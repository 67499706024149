<template>
    <div class="content-wrapper">
        <bo-page-title />
        <div class="content pt-0">
            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Cek No SRB</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                        <div class="col-lg-8" id="formOptPenunjang">
                            <div class="row gx-1">
                            <div class="col-md">
                                <div class="form-group mb-0">
                                <input v-model="inpNoSRB" class="form-control" 
                                placeholder="Ketik No SRB" />
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="form-group mb-0">
                                <input v-model="SEPNoSRB" class="form-control" 
                                placeholder="Ketik No SEP" />
                                </div>
                            </div>
                            <div class="col-md-auto">
                                <button @click="searchNoSRB" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                    class="icon-search4"></i></b>Cari</button>
                                <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetNoSRB">Reset</button>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div class="row pt-2" v-if="shwNoSRB">
                        <div class="col-lg-12">
                            <table class="table table-bordered table-sm text-uppercase">
                                <tbody>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>DPJP</h4>
                                                <p>kode : {{dataNoSRB.prb.DPJP.kode||"-"}}</p>
                                                <p>nama : {{dataNoSRB.prb.DPJP.nama||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Keterangan</h4>
                                            <p>{{dataNoSRB.prb.keterangan||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>noSEP</h4>
                                            <p>{{dataNoSRB.prb.keterangan||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>  
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>noSRB</h4>
                                                <p>{{dataNoSRB.prb.noSRB||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>saran</h4>
                                            <p>{{dataNoSRB.prb.saran||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>tglSRB</h4>
                                            <p>{{dataNoSRB.prb.tglSRB||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>programPRB</h4>
                                                <p>kode : {{dataNoSRB.prb.programPRB.kode||"-"}}</p>
                                                <p>nama : {{dataNoSRB.prb.programPRB.nama||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>  
                                    
                                    <tr>
                                        <td colspan="3">
                                            <div class="result_tab">
                                                <h4>Data Peserta</h4>
                                            </div>
                                        </td>
                                    </tr>  
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>alamat</h4>
                                                <p>{{dataNoSRB.prb.peserta.alamat||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>asalFaskes</h4>
                                                <p>Kode : {{dataNoSRB.prb.peserta.asalFaskes.kode||"-"}}</p>
                                                <p>Nama : {{dataNoSRB.prb.peserta.asalFaskes.nama||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>email</h4>
                                                <p>{{dataNoSRB.prb.peserta.email||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>kelamin</h4>
                                                <p>{{dataNoSRB.prb.peserta.kelamin||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>nama</h4>
                                                <p>Kode : {{dataNoSRB.prb.peserta.nama||"-"}}</p>
                                                <p>Nama : {{dataNoSRB.prb.peserta.nama||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>noKartu</h4>
                                                <p>{{dataNoSRB.prb.peserta.noKartu||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>noTelepon</h4>
                                                <p>{{dataNoSRB.prb.peserta.noTelepon||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>tglLahir</h4>
                                                <p>{{dataNoSRB.prb.peserta.tglLahir||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-card>

            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Cek Tanggal SRB</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                        <div class="col-lg-8" id="formOptPenunjang">
                            <div class="row gx-1">
                            <div class="col-md-6">
                            <div class="input-group">
                                <date-range-picker
                                    ref="picker"
                                    :locale-data="datePickerConfig.locale"
                                    :autoApply="datePickerConfig.autoApply"
                                    v-model="dateRangeTglSRB"
                                    :opens="'right'"
                                    :ranges="datePickerConfig.ranges"
                                >
                                    <template v-slot:input="picker">
                                        <template v-if="picker.startDate && picker.endDate">
                                            {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                        </template>
                                        <template v-else>
                                            Pilih Tanggal
                                        </template>
                                    </template>
                                </date-range-picker>
                                <div class="input-group-append calendar-group">
                                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                </div>
                            </div>
                            </div>
                            <div class="col-md-auto">
                                <button @click="searchTglSRB" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                    class="icon-search4"></i></b>Cari</button>
                                <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetTglSRB">Reset</button>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div class="row pt-2" v-if="shwTglSRB">
                        <div class="col-lg-12 table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>DPJP</th>
                                    <th>keterangan</th>
                                    <th>noSEP</th>
                                    <th>noSRB</th>
                                    <th>peserta</th>
                                    <th>programPRB</th>
                                    <th>saran</th>
                                    <th>tglSRB</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(v,k) in dataTglSRB.prb.list" :key="v.kode">
                                        <td>{{k+1}}</td>
                                        <td>
                                            <p>kode : {{v.DPJP.kode||"-"}}</p>
                                            <p>nama : {{v.DPJP.nama||"-"}}</p>         
                                        </td>
                                        <td>{{v.keterangan||"-"}}</td>
                                        <td>{{v.noSEP}}</td>
                                        <td>{{v.noSRB}}</td>
                                        <td>
                                            <ul>
                                                <li>alamat : {{v.peserta.alamat||"-"}}</li>
                                                <li>email : {{v.peserta.alamat||"-"}}</li>
                                                <li>nama : {{v.peserta.alamat||"-"}}</li>
                                                <li>no kartu : {{v.peserta.alamat||"-"}}</li>
                                                <li>no telepon : {{v.peserta.alamat||"-"}}</li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p>kode : {{v.programPRB.kode||"-"}}</p>
                                            <p>nama : {{v.programPRB.nama||"-"}}</p>         
                                        </td>
                                        <td>{{v.saran||"-"}}</td>
                                        <td>{{v.tglSRB||"-"}}</td>
                                    </tr>
                                </tbody>
                            </table>   
                        </div>
                    </div>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
const _ = global._
import Gen from '@/libs/Gen.js'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
const moment = require('moment')

export default {
  extends: GlobalVue,
  components: {
    DateRangePicker
  },
  data() {
    return {
        inpNoSRB:"",
        SEPNoSRB:"",
        shwNoSRB: false,  
        dataNoSRB: {},

        
        shwTglSRB: false,  
        dateRangeTglSRB: {
            startDate: null,
            endDate: null,
        },
        dataTglSRB: {},

        datePickerConfig: {
            startDate: null,
            endDate: null,
            autoApply: true,
            applyClass: 'btn-sm btn-primary',
            cancelClass: 'btn-sm btn-light',
            ranges: {
                'Hari Ini': [new Date(), new Date()],
                '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
            },
            locale: {
                applyLabel: 'Terapkan',
                cancelLabel: 'Batal',
                direction: 'ltr',
                format: 'mm/dd/yyyy',
                separator: ' - ',
            }
        },
        
    }
  },
  
  filters: {
    date(val) {
    return val ? moment(val).format("D MMM YYYY") : ""
    }
  },
  
  methods: {
    searchNoSRB(){
        if(this.inpNoSRB){
            this.loadingOverlay = true
            let data = {
                type : "search-nomor-srb",
                noSRB : this.inpNoSRB,
                noSEP : this.SEPNoSRB,
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwNoSRB = false
                    return this.$swal({
                        icon: 'error',
                        text: 'Data Nomor SRB Tidak Ditemukan'
                    })
                }
                this.dataNoSRB = resp.response
                this.loadingOverlay = false
                this.shwNoSRB = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    text: 'Data Nomor SRB Tidak Ditemukan'
                })
            })
        }
    },
    resetNoSRB(){
        this.inpNoSRB = null
        this.shwNoSRB = false
        this.dataNoSRB = {}
    },
    
    searchTglSRB(){
        if(this.dateRangeTglSRB.startDate && this.dateRangeTglSRB.endDate){
            this.loadingOverlay = true
            let data = {
                type : "search-tanggal-srb",
                startDate : this.dateRangeTglSRB.startDate,
                endDate : this.dateRangeTglSRB.endDate,
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwTglSRB = false
                    return this.$swal({
                        icon: 'error',
                        text: 'Data Tanggal SRB Tidak Ditemukan'
                    })
                }
                this.dataTglSRB = resp.response
                this.loadingOverlay = false
                this.shwTglSRB = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    text: 'Data Tanggal SRB Tidak Ditemukan'
                })
            })
        }
    },
    resetTglSRB(){
        this.inpTglSRB = null
        this.shwTglSRB = false
        this.dateRangeTglSRB.startDate = null
        this.dateRangeTglSRB.endDate = null
    },
  }
}

</script>

<style lang="scss" style="scoped">
    .vue-daterange-picker{
        width: 285px;
    }
</style>